'use client';
import React, { useState, useRef, useMemo, useEffect, useCallback, useContext } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useSearch } from './search';
import { LocalizedLink } from '@/components/Link';
import CloseIcon from './CloseIcon';
import SearchIcon from './SearchIcon';
import { useGamefeed } from './model';
import { AnalyticsContextView } from '@/features/arena-data/view';
import { useDeviceDetector } from '@/shared/utils/userAgentContext';
import styles from './styles.module.css';
import type { Anchor, Root, Content } from '@radix-ui/react-popover';
import { getLocaleFromPathname } from '@/shared/utils/url';
import { usePathname } from 'next/navigation';

type SearchProps = {
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    className?: string;
};

type SearchRenderProps = {
    isOpen: boolean;
    loadPopover: boolean;
    children: React.ReactNode;
    handleOutsideClick: any;
    renderContent: any;
}

type Match = { key: string; value: string };

type PointerDownOutsideEvent = CustomEvent<{
    originalEvent: PointerEvent;
}>;

type FocusOutsideEvent = CustomEvent<{
    originalEvent: FocusEvent;
}>;

const SearchRender = ({ isOpen, loadPopover, handleOutsideClick, renderContent, children }: SearchRenderProps) => {
    const [popoverComponents, setPopoverComponents] =
        useState<{ root: typeof Root, anchor: typeof Anchor, content: typeof Content } | null>(null);

    const renderResult = useMemo(() => {
        if (!loadPopover || !popoverComponents) return children;
        const { root: PopoverRoot, anchor: PopoverAnchor, content: PopoverContent } = popoverComponents;
        return (
            <PopoverRoot open={isOpen}>
                <PopoverAnchor className={styles.searchAnchor}>
                    {children}
                </PopoverAnchor>
                <PopoverContent
                    onOpenAutoFocus={(e) => {
                        e.preventDefault();
                    }}
                    onInteractOutside={handleOutsideClick}
                    className={`${styles.popoverContent} padding-40-right padding-40-left `}
                >
                    {renderContent()}
                </PopoverContent>
            </PopoverRoot>
        )
    }, [loadPopover, popoverComponents, isOpen, renderContent, handleOutsideClick])

    useEffect(() => {
        if (!loadPopover || popoverComponents) return;
        import('@radix-ui/react-popover').then(({ Root, Anchor, Content }) => {
            setPopoverComponents({ root: Root, anchor: Anchor, content: Content });
        })

    }, [loadPopover])

    return renderResult as React.ReactElement;
}

export const Search = ({ onFocus, onBlur, className }: SearchProps) => {
    const router = useRouter();
    const [loadPopover, setLoadPopover] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const { isMobile, isNotPc } = useDeviceDetector();
    const isMobileDetected = isMobile();
    const { AITracks } = useContext(AnalyticsContextView);
    const pathname = usePathname();

    const searchOptions = useMemo(
        () => ({
            keys: ['name', 'meta.categories.name', 'meta.tags', 'meta.badges', 'meta.name'],
            threshold: 0.3,
            includeMatches: true,
        }),
        []
    );

    const mergedGamefeed = useGamefeed();

    const { query, setQuery, results, isSearching, isMalicious } = useSearch(mergedGamefeed, searchOptions);

    const isOpen = query.length >= 3;

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const locale = getLocaleFromPathname(pathname);
        if (query.trim()) {
            router.push(`/${locale}/search/${encodeURIComponent(query.trim())}`);
        }
    };

    const handleOutsideClick = (
        e: React.MouseEvent<HTMLAnchorElement> | PointerDownOutsideEvent | FocusOutsideEvent
    ) => {
        const target = e.target as Node;
        if (containerRef.current && !containerRef.current.contains(target)) {
            setIsFocused(false);
            setQuery('');
            onBlur && onBlur(e as any);
        }
    };

    const clearInput = useCallback(
        (e: React.MouseEvent<HTMLButtonElement> | KeyboardEvent) => {
            setQuery('');
            setIsFocused(false);
            if (inputRef.current) {
                inputRef.current.placeholder = 'Search';
            }
            onBlur && onBlur(e as any);
        },
        [onBlur, setQuery]
    );

    const handleEscKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                clearInput(event);
            }
        },
        [clearInput]
    );

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => handleEscKeyDown(event);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleEscKeyDown]);

    const trimQuery = (query: string, maxLength: number) => {
        if (query.length > maxLength) {
            return `${query.substring(0, maxLength)}...`;
        }
        return query;
    };

    const { games, categories } = useMemo(() => {
        if (query.length < 3) {
            return { games: [], categories: [] };
        }

        const categoryMap = new Map();

        const formattedGames = results
            ? results.map((result) => {
                return {
                    name: result.item.meta.name || result.item.name,
                    image: result.item.meta.thumbs.graphic_56x56,
                    slug: 'games',
                    param: result.item.slug,
                };
            })
            : [];

        results?.forEach((result) => {
            result.matches
                .filter((match: Match) => match.key === 'meta.categories.name')
                .forEach((match: Match) => {
                    const categoryObj = result.item.meta.categories.find(
                        (cat: { name: string }) => cat.name === match.value
                    );
                    if (categoryObj) {
                        categoryMap.set(match.value, {
                            name: match.value,
                            image: categoryObj.image,
                            slug: categoryObj.slug,
                            param: encodeURIComponent(match.value.toLowerCase().trim().replace(/\s+/g, '')),
                        });
                    }
                });
        });

        return {
            games: formattedGames,
            categories: Array.from(categoryMap.values()),
        };
    }, [results, query]);

    const noResults = results?.length === 0;

    const toggleScrollableBody = (scrollable: boolean) => {
        document.body.style.position = scrollable ? 'initial' : 'fixed';
        document.body.style.overflow = scrollable ? 'auto' : 'hidden';
        document.body.style.touchAction = scrollable ? 'auto' : 'none';
    };

    const renderContent = () => {
        const popoverContent = containerRef.current?.querySelector('[data-radix-popper-content-wrapper]');
        if (typeof document !== 'undefined' && isNotPc() && isFocused && popoverContent) {
            toggleScrollableBody(false);
        }

        if (isMalicious) {
            return (
                <div className={`${styles.noResults}`}>
                    Oops! There was an issue with the search terms provided. Please review your search terms and try
                    again.
                </div>
            );
        }

        if (isSearching) {
            return <div className={`${styles.noResults}`}>Loading...</div>;
        }

        if (noResults) {
            return <div className={`${styles.noResults}`}>Sorry, no results found...</div>;
        }

        return (
            <>
                <div className={styles.resultsContainer}>
                    <ResultList items={categories} title="Categories" onClick={handleOutsideClick} />
                    <ResultList items={games} title="Games" onClick={handleOutsideClick} />
                </div>
                <div
                    className={`${styles.seeAllResults} padding-16-bottom padding-16-top margin-40-right margin-40-left`}
                >
                    <div className={`${styles.seeAllGradientOverlay} ${styles.gradientWhite}`}></div>
                    <LocalizedLink
                        className={`text-16 fw-regular ${styles.seeAllResultsAnchor}`}
                        href={`/search/${query}`}
                    >
                        <SearchIcon />
                        <span className="margin-4-left">See all</span>
                        <strong className="fw-semibold">&nbsp;&quot;{trimQuery(query, 20)}&quot;&nbsp;</strong>
                        results
                    </LocalizedLink>
                </div>
            </>
        );
    };

    const showCloseIcon = query || isMobileDetected;

    useEffect(() => {
        if (!isFocused && isNotPc()) {
            toggleScrollableBody(true);
        }
        isFocused && AITracks.searchNav();
        if (isFocused && typeof document !== 'undefined') {
            const sidebarToggle: any = document?.body?.querySelector('#sidebarToggle');
            if (sidebarToggle) {
                sidebarToggle.checked = false;
            }
        }
        const mainElement = document.querySelector('main');
        if (mainElement) {
            mainElement.style.pointerEvents = isFocused ? 'none' : '';
        }
        return () => {
            if (mainElement) {
                mainElement.style.pointerEvents = '';
            }
        };
    }, [isFocused]);

    const handleInputTouchStart = () => {
        // Fix for Firefox on Android: Prevent scrolling for mobile while focusing - 400 is animation delay
        toggleScrollableBody(false);
        setTimeout(() => {
            toggleScrollableBody(true);
        }, 400);
    };
    const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setLoadPopover(true);
        e.target.placeholder = '';
        setIsFocused(true);
        onFocus && onFocus(e);
    };

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!isOpen) {
            setQuery('');
            setIsFocused(false);
            e.target.placeholder = 'Search'; // Restore placeholder on blur
            onBlur && onBlur(e);
        }
    };

    return (
        <div ref={containerRef} className={`${styles.searchContainer}`}>
            <SearchRender
                handleOutsideClick={handleOutsideClick}
                isOpen={isOpen}
                renderContent={renderContent}
                loadPopover={loadPopover}>
                <form onSubmit={handleSubmit} className="relative">
                    <div className={styles.searchIcon}>
                        <SearchIcon />
                    </div>
                    <input
                        ref={inputRef}
                        type="text"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder="Search"
                        onTouchStart={handleInputTouchStart}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        className={`${className} ${styles.searchInput} ${isFocused ? styles.searchInputFocused : ''
                            } ${isFocused ? 'ark-ui-search-active' : 'ark-ui-search'}`}
                    />
                    {showCloseIcon && (
                        <button
                            type="button"
                            onClick={clearInput}
                            className={`${styles.clearButton} ${isFocused ? styles.clearButton__visible : ''}`}
                            aria-label="Clear"
                        >
                            <CloseIcon />
                        </button>
                    )}
                </form>
            </SearchRender>
        </div>
    );
};

type ResultItem = {
    name: string;
    image: string;
    slug: string;
    param: string;
};

type ResultListProps = {
    items: ResultItem[];
    title: string;
    onClick: (event: any) => void;
};

const ResultList: React.FC<ResultListProps> = ({ items, title, onClick }) => {
    return (
        <div className={styles.columnContainer}>
            <h3 className={`text-20 fw-semibold ${styles.resultTitle}`}>{title}</h3>
            <div
                className={`${styles.resultList} ${title?.toLowerCase() === 'categories'
                    ? styles.categoriesContainer + ' margin-56-right'
                    : styles.gamesContainer
                    } `}
            >
                {items.length === 0 ? (
                    <div className="text-16 fw-regular">Sorry, no categories found...</div>
                ) : (
                    items.map((item, index) => (
                        <LocalizedLink
                            className={styles.resultItemLink}
                            key={index}
                            href={`/${item.slug}/${item.param}`}
                            onClick={onClick}
                            passHref
                        >
                            <div className={styles.resultItem}>
                                <div className={styles.resultImageContainer}>
                                    {!item.image ? (
                                        <div
                                            style={{ width: '56px', height: '56px', backgroundColor: '#ddd' }}
                                            className={styles.resultImage}
                                        ></div>
                                    ) : (
                                        <Image
                                            src={item.image}
                                            className={styles.resultImage}
                                            width={56}
                                            height={56}
                                            alt={item.name}
                                        />
                                    )}
                                </div>
                                <div className={`text-16 fw-regular ${styles.capitalize}`}>{item.name}</div>
                            </div>
                        </LocalizedLink>
                    ))
                )}
            </div>
            <div className={`${styles.columnGradientOverlay} ${styles.gradientWhite}`}></div>
        </div>
    );
};

export default ResultList;
